<template>
  <div
    class="content"
    style="padding: 20px"
  >
    <base-loading v-if="loading" />
    <div v-else-if="( selecaoFazenda === null || selecaoFazenda === '' || selecaoFazenda === undefined )">
      <v-alert
        color="warning"
        icon="mdi-account-cowboy-hat"
        dark
        dismissible
      >
        <span> <h2>{{ this.$store.state.user.roles[0].slug === 'tecnico' ? 'Por favor selecione um Produtor e uma Fazenda no canto superior direito da loja.' : 'Por favor selecione uma Fazenda no canto superior direito da loja.' }} </h2></span>
      </v-alert>
    </div>
    <div v-else-if="this.$store.getters['loja/catalogoFiltered'].length === 0 && !loading">
      <v-alert
        color="error"
        icon="mdi-cow"
        dark
        dismissible
      >
        <span> <h2>Não existem produtos cadastrados para a Fazenda selecionada.</h2></span>
      </v-alert>
    </div>
    <div v-else>
      <div
        class="font-weight-bold mb-2"
        style="font-size: 24px; color: #183B94;"
      >
        Todos produtos
      </div>
      <v-row>
        <v-col cols="12">
          <v-row
            style="min-height: 70px"
          >
            <v-col
              cols="12"
              sm="12"
              md="12"
              lg="8"
              xl="9"
            >
              <v-text-field
                v-model="search"
                style="background-color: white; border-radius: 8px"
                append-icon="mdi-magnify"
                label="Pesquisar"
                dense
                solo
                elevation="1"
                hide-details
                clearable
                clear-icon="mdi-close"
              />
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="12"
              lg="4"
              xl="3"
            >
              <v-select
                v-model="sortBy"
                dense
                solo
                hide-details
                item-text="text"
                item-value="value"
                :items="keys"
                label="Ordenar por"
                class="selecao"
                background-color="white"
                :menu-props="{ 'style': 'color: #183B94' }"
                style="color: #183B94; font-weight: 700; border-radius: 8px"
                @change="ordena"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="4"
          lg="3"
          xl="3"
        >
          <v-card class="py-3 mt-0 mr-1">
            <v-card-title
              style="font-size: 18px;
              line-height: normal;
              text-align: left;
              margin: 0px;
              font-weight: bold;
              color: #003677"
              @click="handleFiltrarProdutosClick()"
            >
              Filtrar Produtos
              <v-icon v-if="!$vuetify.breakpoint.mdAndUp">
                {{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
              </v-icon>
            </v-card-title>
            <v-expand-transition>
              <div v-show="show">
                <a
                  color="black"
                  class="primary--text"
                  style="text-decoration:none;"
                  @click.prevent="resetFiltros"
                >
                  <p
                    class="px-10 mb-0 mt-3"
                  >
                    Limpar filtros
                    <v-icon small>
                      mdi-trash-can-outline
                    </v-icon>
                  </p>
                </a>
                <v-list>
                  <v-list-item
                    v-for="item in filterOpts"
                    :key="item.key"
                  >
                    <v-list
                      v-if="item.key === 'catalogo_promo'"
                      class="mx-0"
                      style="overflow-x: auto;"
                      flat
                      dense
                      :width="$vuetify.breakpoint.xlOnly ? 350 : $vuetify.breakpoint.smOnly || $vuetify.breakpoint.xsOnly ? 880 : 250"
                    >
                      <v-subheader
                        style="height: 25px;"
                        class="subheader"
                      >
                        {{ item.text }}
                      </v-subheader>
                      <v-divider
                        style="border-color: #003677;"
                        class="mb-2"
                      />
                      <v-list-item-group
                        v-model="item.value"
                        multiple
                        @change="handleFilterInput(item)"
                      >
                        <v-list-item
                          v-for="promo in promos"
                          :key="promo.id"
                          :value="promo"
                          style="min-height: 30px;"
                        >
                          <template v-slot:default="{ active, }">
                            <v-list-item-action class="mr-1  my-0">
                              <v-checkbox
                                :input-value="active"
                                color="primary"
                              />
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title style="display: flex; gap: 3px">
                                <span>{{ promo.name }}</span>  <span style="font-size: 12px; font-weight: bold; text-align: center; display: flex; justify-content: center; align-items: center; background-color: #e0e0e0; width: 30px; border-radius: 10px;">{{ promo.qtd }}</span>
                              </v-list-item-title>
                            </v-list-item-content>
                          </template>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                    <v-list
                      v-if="item.key === 'catalogo_preco'"
                      class="mx-0"
                      style="overflow-x: auto;"
                      flat
                      dense
                      :width="$vuetify.breakpoint.xlOnly ? 350 : $vuetify.breakpoint.smOnly || $vuetify.breakpoint.xsOnly ? 880 : 250"
                    >
                      <v-subheader
                        style="height: 25px;"
                        class="subheader"
                      >
                        {{ item.text }}
                      </v-subheader>
                      <v-divider
                        style="border-color: #003677;"
                        class="mb-2"
                      />

                      <v-list-item-group
                        v-model="item.value"
                        multiple
                        @change="handleFilterInput(item)"
                      >
                        <v-list-item
                          v-for="(preco, index) in precos"
                          :key="index"
                          :value="preco"
                          style="min-height: 30px;"
                        >
                          <template v-slot:default="{ active, }">
                            <v-list-item-action class="mr-1 my-0">
                              <v-checkbox
                                :input-value="active"
                                color="primary"
                              />
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title
                                v-if="preco.length === 3"
                                style="display: flex; gap: 3px"
                              >
                                <span>R$ {{ currencyFormatter(preco[0]) }} - R$ {{ currencyFormatter(preco[1]) }}</span> <span style="font-size: 12px; font-weight: bold; text-align: center; display: flex; justify-content: center; align-items: center; background-color: #e0e0e0; width: 30px; border-radius: 10px;">{{ preco[2] }}</span>
                              </v-list-item-title>
                              <v-list-item-title
                                v-else
                                style="display: flex; gap: 3px"
                              >
                                <span>+ R$ {{ currencyFormatter(preco[0]) }}</span> <span style="font-size: 12px; font-weight: bold; text-align: center; display: flex; justify-content: center; align-items: center; background-color: #e0e0e0; width: 30px; border-radius: 10px;">{{ preco[1] }}</span>
                              </v-list-item-title>
                            </v-list-item-content>
                          </template>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                    <v-list
                      v-if="item.key === 'catalogo_desconto'"
                      class="mx-0"
                      style="overflow-x: auto;"
                      flat
                      dense
                      :width="$vuetify.breakpoint.xlOnly ? 350 : $vuetify.breakpoint.smOnly || $vuetify.breakpoint.xsOnly ? 880 : 250"
                    >
                      <v-subheader
                        style="height: 25px;"
                        class="subheader"
                      >
                        {{ item.text }}
                      </v-subheader>
                      <v-divider
                        style="border-color: #003677;"
                        class="mb-2"
                      />

                      <v-list-item-group
                        v-model="item.value"
                        multiple
                        @change="handleFilterInput(item)"
                      >
                        <v-list-item
                          v-for="desconto in descontos"
                          :key="parseFloat(desconto)"
                          :value="desconto"
                          style="min-height: 30px;"
                        >
                          <template v-slot:default="{ active, }">
                            <v-list-item-action class="mr-1 my-0">
                              <v-checkbox
                                :input-value="active"
                                color="primary"
                              />
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title style="display: flex; gap: 3px">
                                <span>{{ desconto[0] }}% - {{ desconto[1] }}%</span>  <span style="font-size: 12px; font-weight: bold; text-align: center; display: flex; align-items: center; justify-content: center; background-color: #e0e0e0; width: 30px; border-radius: 10px;">{{ desconto[2] }}</span>
                              </v-list-item-title>
                            </v-list-item-content>
                          </template>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                    <v-list
                      v-if="item.key === 'catalogo_categoria'"
                      class="mx-0"
                      style="overflow-x: auto;"
                      flat
                      dense
                      :width="$vuetify.breakpoint.xlOnly ? 350 : $vuetify.breakpoint.smOnly || $vuetify.breakpoint.xsOnly ? 880 : 250"
                    >
                      <v-subheader
                        style="height: 25px;"
                        class="subheader"
                      >
                        {{ item.text }}
                      </v-subheader>
                      <v-divider
                        style="border-color: #003677;"
                        class="mb-2"
                      />

                      <v-list-item-group
                        v-model="item.value"
                        multiple
                        @change="handleFilterInput(item)"
                      >
                        <v-list-item
                          v-for="categoria in categorias"
                          :key="categoria.id"
                          :value="categoria.nome"
                          style="min-height: 30px;"
                        >
                          <template v-slot:default="{ active, }">
                            <v-list-item-action class="mr-1  my-0">
                              <v-checkbox
                                :input-value="active"
                                color="primary"
                              />
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title style="display: flex; gap: 3px">
                                <span>{{ categoria.nome }}</span>  <span style="font-size: 12px; font-weight: bold; text-align: center; display: flex; justify-content: center; align-items: center; background-color: #e0e0e0; width: 30px; border-radius: 10px;">{{ categoria.qtd }}</span>
                              </v-list-item-title>
                            </v-list-item-content>
                          </template>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                    <v-list
                      v-if="item.key === 'catalogo_marca'"
                      style="overflow-x: auto;"
                      flat
                      dense
                      :width="$vuetify.breakpoint.xlOnly ? 350 : $vuetify.breakpoint.smOnly || $vuetify.breakpoint.xsOnly ? 880 : 250"
                      class="mx-0"
                    >
                      <v-subheader
                        style="height: 25px;"
                        class="subheader"
                      >
                        {{ item.text }}
                      </v-subheader>
                      <v-divider
                        style="border-color: #003677;"
                        class="mb-2"
                      />
                      <v-list-item-group
                        v-model="item.value"
                        multiple
                        @change="handleFilterInput(item)"
                      >
                        <v-list-item
                          v-for="marca in marcas"
                          :key="marca.name"
                          :value="marca"
                          style="min-height: 30px;"
                        >
                          <template v-slot:default="{ active, }">
                            <v-list-item-action class="mr-1  my-0">
                              <v-checkbox
                                :input-value="active"
                                color="primary"
                              />
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title style="display: flex; gap: 3px">
                                <span>{{ marca.descricao }} </span>  <span style="font-size: 12px; font-weight: bold; text-align: center; display: flex; justify-content: center; align-items: center; background-color: #e0e0e0; width: 30px; border-radius: 10px;">{{ marca.qtd }}</span>
                              </v-list-item-title>
                            </v-list-item-content>
                          </template>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-list-item>
                </v-list>
              </div>
            </v-expand-transition>
          </v-card>
        </v-col>
        <v-col
          sm="12"
          md="8"
          lg="9"
        >
          <v-data-iterator
            :items="filteredCatalogo"
            :items-per-page.sync="itemsPerPage"
            :page.sync="page"
            :search="search"
            :sort-by="sortBy"
            :sort-desc="sortDesc"
            :no-data-text="'Não existem produtos para os filtros selecionados.'"
            :no-results-text="'Não foram encontrados produtos para essa pesquisa.'"
            hide-default-footer
          >
            <template v-slot:default="props">
              <v-row>
                <v-col
                  v-for="item in props.items"
                  :key="item.id"
                  cols="12"
                  sm="6"
                  md="6"
                  lg="4"
                  xl="3"
                  class="mb-6"
                  style="display: flex; justify-content: center;"
                >
                  <card-produto
                    :produto="item"
                  />
                </v-col>
              </v-row>
            </template>
          </v-data-iterator>
        </v-col>
      </v-row>
    </div>
    <campaign-dialog />
  </div>
</template>

<script>
  import { BOOTSTRAP } from '@/store/modules/loja'
  import { mapState, mapGetters, mapActions } from 'vuex'
  import CardProduto from './_cardProduto.vue'
  import { currencyFormatter } from '../../utils/formatter'
  import CampaignDialog from './_campanhaDialog.vue'

  export default {
    components: {
      CardProduto,
      CampaignDialog,
    },
    props: {
      ordenacao: {
        type: String,
        default: '',
      },
      campanha: {
        type: String,
        default: '',
      },
    },
    data: () => ({
      model: null,
      search: '',
      filter: {},
      show: true,
      sortDesc: false,
      page: 1,
      itemsPerPage: 12,
      sortBy: 'nome',
      camp: '',
      keys: [
        { text: 'Ordenar por:  Nome', value: 'nome' },
        { text: 'Ordenar por: Categoria', value: 'categoria' },
        { text: 'Ordenar por: Marca', value: 'marca' },
        { text: 'Ordenar por: Maiores descontos', value: 'desconto' },
        { text: 'Ordenar por: Menores preços', value: 'precoWithDiscount' },
        { text: 'Ordenar por: Maiores preços', value: 'precoWithDescend' },
      ],
      filterOpts: [
        {
          value: [],
          key: 'catalogo_promo',
          checked: false,
          text: 'Promoções',
        },
        {
          value: [],
          key: 'catalogo_preco',
          checked: false,
          text: 'Faixa de preços',
        },
        {
          value: [],
          key: 'catalogo_desconto',
          checked: false,
          text: 'Faixa de descontos',
        },
        {
          value: [],
          key: 'catalogo_categoria',
          checked: false,
          text: 'Categorias',
        },
        {
          value: [],
          key: 'catalogo_marca',
          checked: false,
          text: 'Marcas',
        },
      ],
      currencyFormatter,
    }),
    computed: {
      ...mapState('loja', ['loading']),
      ...mapState('produtoIndividual', ['ref']),
      ...mapGetters('loja', ['categorias', 'marcas', 'promos', 'catalogoFiltered']),
      ...mapState('fazendas', ['selecaoFazenda']),
      numberOfPages () {
        return Math.ceil(this.catalogoFiltered.length / this.itemsPerPage)
      },
      numFilters () {
        return this.filterOpts.filter((v) => v.checked && v.value.length > 0).length
      },
      filteredCatalogo () {
        const [catalogo_promo, catalogo_preco, catalogo_desconto, catalogo_categoria, catalogo_marca] = this.filterOpts
        return this.numFilters ? this.catalogoFiltered.filter(({ categoria, marca, precoWithDiscount, id_campanha, desconto }) =>
          (!(catalogo_preco.checked && catalogo_preco.value.length > 0) || catalogo_preco.value.filter(faixa => parseFloat(precoWithDiscount) >= parseFloat(faixa[0]) && (faixa.length === 3 ? (parseFloat(precoWithDiscount) < parseFloat(faixa[1])) : true)).length > 0) &&
          (!(catalogo_desconto.checked && catalogo_desconto.value.length > 0) || catalogo_desconto.value.filter(faixa => parseFloat(faixa[0]) <= parseFloat(desconto) && (parseFloat(desconto) <= parseFloat(faixa[1]))).length > 0) &&
          (!(catalogo_categoria.checked && catalogo_categoria.value.length > 0) || catalogo_categoria.value.includes(categoria.nome)) &&
          (!(catalogo_promo.checked && catalogo_promo.value.length > 0) || catalogo_promo.value.filter(el => el.id === id_campanha).length > 0) &&
          (!(catalogo_marca.checked && catalogo_marca.value.length > 0) || catalogo_marca.value.filter(el => el.name === marca).length > 0),
        ) : this.catalogoFiltered
      },
      precos () {
        const newCatalogo = this.catalogoFiltered
        const catalogo_ordered_by_preco = newCatalogo.sort((a, b) => parseFloat(a.precoWithDiscount) - parseFloat(b.precoWithDiscount))
        const first = catalogo_ordered_by_preco[0].precoWithDiscount
        const second = Math.floor(catalogo_ordered_by_preco.length / 6)
        const third = Math.floor(catalogo_ordered_by_preco.length / 4)
        const fourth = Math.floor(catalogo_ordered_by_preco.length / 2)
        const five = Math.floor(catalogo_ordered_by_preco.length / 1.33)

        let faixa1 = 0; let faixa2 = 0; let faixa3 = 0; let faixa4 = 0; let faixa5 = 0

        catalogo_ordered_by_preco.forEach(item => {
          if (parseFloat(item.precoWithDiscount) >= parseFloat(first) && parseFloat(item.precoWithDiscount) < parseFloat(catalogo_ordered_by_preco[second].precoWithDiscount)) {
            faixa1++
          } else if (parseFloat(item.precoWithDiscount) >= parseFloat(catalogo_ordered_by_preco[second].precoWithDiscount) && parseFloat(item.precoWithDiscount) < parseFloat(catalogo_ordered_by_preco[third].precoWithDiscount)) {
            faixa2++
          } else if (parseFloat(item.precoWithDiscount) >= parseFloat(catalogo_ordered_by_preco[third].precoWithDiscount) && parseFloat(item.precoWithDiscount) < parseFloat(catalogo_ordered_by_preco[fourth].precoWithDiscount)) {
            faixa3++
          } else if (parseFloat(item.precoWithDiscount) >= parseFloat(catalogo_ordered_by_preco[fourth].precoWithDiscount) && parseFloat(item.precoWithDiscount) < parseFloat(catalogo_ordered_by_preco[five].precoWithDiscount)) {
            faixa4++
          } else if (parseFloat(item.precoWithDiscount) >= parseFloat(catalogo_ordered_by_preco[five].precoWithDiscount)) {
            faixa5++
          }
        })

        return [[first, parseFloat(catalogo_ordered_by_preco[second].precoWithDiscount) - 0.01, faixa1], [catalogo_ordered_by_preco[second].precoWithDiscount, parseFloat(catalogo_ordered_by_preco[third].precoWithDiscount) - 0.01, faixa2], [catalogo_ordered_by_preco[third].precoWithDiscount, parseFloat(catalogo_ordered_by_preco[fourth].precoWithDiscount) - 0.01, faixa3], [catalogo_ordered_by_preco[fourth].precoWithDiscount, parseFloat(catalogo_ordered_by_preco[five].precoWithDiscount) - 0.01, faixa4], [catalogo_ordered_by_preco[five].precoWithDiscount, faixa5]]
      },
      descontos () {
        const newCatalogo = this.catalogoFiltered.filter(({ desconto }) => (parseFloat(desconto) > 0 && desconto !== undefined))
        const catalogo_ordered_by_desconto = newCatalogo.sort((a, b) => parseFloat(a.desconto) - parseFloat(b.desconto))
        const menu = []
        let inicio = 1
        let final = 10

        for (let i = 10; i <= 100; i = i + 10) {
          const opt = []
          let qtd = 1
          catalogo_ordered_by_desconto.forEach(opcao => {
            if (opcao.desconto >= inicio && opcao.desconto <= final) {
              if (!opt.includes(inicio)) {
                opt.push(inicio, final, 0)
              } else {
                qtd += 1
              }
            }
          })
          if (opt.length > 0) {
            opt[2] = qtd
            menu.push(opt)
          }
          inicio = final + 1
          final = final + 10
        }
        return menu
      },
    },
    async created () {
      if (this.$store.getters['loja/catalogoFiltered'].length === 0) {
        await this.$store.dispatch('loja/BOOTSTRAP')
      }
      this.BOOTSTRAP()
      this.show = !!this.$vuetify.breakpoint.mdAndUp
      const ref = this.$router.currentRoute.path
      this.$store.commit('produtoIndividual/SET_STATE', { ref })
      this.camp = this.campanha

      if (this.ordenacao !== '') {
        this.sortBy = this.ordenacao
        this.ordena(this.ordenacao)
      }
    },
    updated () {
      if (this.camp !== '') {
        const promocao = this.promos.filter(el => el.id === this.camp)
        if (promocao.length > 0) {
          const filtro = this.filterOpts.filter(el => el.key === 'catalogo_promo')
          if (filtro.length > 0) {
            filtro[0].value = promocao
            this.camp = ''
            this.handleFilterInput(filtro[0])
          }
        }
      }
    },
    mounted () {
      this.getNextProdutos()
    },
    methods: {
      ...mapActions('loja', [BOOTSTRAP]),
      handleBackButton () {
        this.$router.push({ path: 'dashboard' }).catch(() => {})
        this.$router.push({ path: 'loja' }).catch(() => {})
      },
      getNextProdutos () {
        window.onscroll = () => {
          const bottomOfWindow = document.documentElement.scrollHeight - (document.documentElement.scrollTop + window.innerHeight)
          if (bottomOfWindow < 1) {
            this.itemsPerPage = this.itemsPerPage + this.itemsPerPage
          }
        }
      },
      handleFilterInput (item) {
        item.checked = item.value.length > 0 || (item.value.length > 0 && item.checked)
      },
      handleFiltrarProdutosClick () {
        this.show = !this.show
      },
      resetFiltros () {
        this.filterOpts.forEach(filter => { filter.value = [] })
        this.voltar_topo()
      },
      ordena (item) {
        if (item === 'precoWithDescend') {
          this.sortDesc = true
        } else if (item === 'desconto') {
          this.sortDesc = true
        } else {
          this.sortDesc = false
        }
      },
      voltar_topo () {
        if (window.scrollY > 50) {
          window.scrollTo({
            top: 0,
            behavior: 'smooth',
          })
        }
      },
    },
  }
</script>

<style>
.theme--light.v-select .v-select__selections {
    color: #183B94;
}
</style>

<style scoped>
.v-application .primary--text {
    color: #003677 !important;
    caret-color: #003677 !important;
}

.selecao .theme--light.v-text-field--filled > .v-input__control > .v-input__slot {
    background: none;
}

.subheader {
  font-size: 16px;
  line-height: normal;
  text-align: left;
  margin: 0px;
  font-weight: bold;
  color: #003677;
}

.v-card {
  border-radius: 10px;
}

</style>
